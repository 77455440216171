<template>
  <div class="flex items-center space-x-2 w-fit max-w-48">
    <div v-if="iconData" class="w-4 h-4">
      <icon-base
        v-tooltip="
          $t(
            `ORGANIZATION.EMAIL_TEMPLATES.${iconData.icon.toUpperCase()}_TOOLTIP`
          )
        "
        v-bind="iconData"
        :data-cy="`icon-${iconData.icon}`"
        class="focus:outline-none"
      />
    </div>
    <span
      v-tooltip="model.name"
      class="text-headline line-clamp-2"
      @click="showTemplate"
    >
      {{ model.name }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { EmailTemplateVisibility } from "@/enums/emailCustomization";
import { UTIL_COLORS } from "@/helpers/constants";
import type { EmailTemplate } from "@/models/clients";
import { ROUTE_EMAIL_TEMPLATES_EDIT } from "@/router/routes";
import { computed, type PropType } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  model: {
    type: Object as PropType<EmailTemplate>,
    required: true
  }
});

const router = useRouter();

const iconData = computed(() => {
  if (props.model.visibility === EmailTemplateVisibility.Global) {
    return {
      icon: "globe",
      iconColor: UTIL_COLORS.PRIMARY
    };
  }

  if (props.model.visibility === EmailTemplateVisibility.Organizational) {
    return {
      icon: "organization",
      iconColor: UTIL_COLORS.PRIMARY
    };
  }

  return null;
});

const showTemplate = async () => {
  if (!props.model?.id) {
    return;
  }
  const pushPayload = {
    name: ROUTE_EMAIL_TEMPLATES_EDIT,
    params: {
      templateId: props.model.id
    }
  };

  await router.push(pushPayload);
};
</script>
